<template>
  <div>
    <div class="flex py-2">
      <RikazCollection
        v-if="categories?.length"
        ref="collectionRef1"
        :enable-actions-leave-mouse="false"
        class-content="relative"
      >
        <template #trigger="collection">
          <div class="flex justify-center">
            <div
              class="mx-1 cursor-pointer sm:mx-2"
              :class="[
                { 'text-nav_color': selectedIndex == -1 && !isDomain },
                {
                  'text-text_alt_color':
                    selectedIndex == -1 && isDomain
                }
              ]"
              @click=";[collection.setIndex(null), goToCategory(undefined)]"
            >
              <div class="h-8 xs:h-12 sm:h-16 md:h-20 lg:h-24">
                <div
                  class="p-1 sm:p-2 flex items-center justify-center rounded-lg bg-opacity-20"
                >
                  <v-icon icon="mdi mdi-view-grid" class="custom-font-size" />
                </div>
              </div>
              <div class="pt-1 text-center mt-auto text-xs">{{ t('all') }}</div>
            </div>
            <RikazHorizontalDrag class="flex max-w-max">
              <div class="flex">
                <button
                  v-for="(item, index) in categories"
                  :key="`store-filter-order-${item.name}`"
                  class="text-xs mx-1 sm:mx-2 z-0"
                  @click=";[collection.setIndex(index), goToCategory(index)]"
                >
                  <div class="w-8 xs:w-12 sm:w-16 md:w-20 lg:w-24 mx-auto">
                    <NuxtImg
                      provider="cacheIpx"
                      preset="modified"
                      :src="item.image"
                      :alt="item.name"
                      width="128"
                      height="128"
                      class="mx-auto aspect-square rounded-full object-cover border"
                    />
                  </div>
                  <div
                    class="py-1 pb-1 w-14 xs:w-18 sm:w-24 md:32 lg:w-36"
                    :class="[
                      { 'text-nav_color': selectedIndex == index && !isDomain },
                      {
                        'text-text_alt_color':
                          selectedIndex == index && isDomain
                      }
                    ]"
                  >
                    <ClientOnly>{{ item.name }}</ClientOnly>
                  </div>
                </button>
              </div>
            </RikazHorizontalDrag>
          </div>
        </template>

        <template #content="{ index }">
          <!--  close -->
          <div v-if="selectedIndex == index && childrenCategory[index]?.length">
            <div class="border-t border-text_color max-w-[50%] mx-auto"></div>
            <StoresFilterCategoriesChildren
              :with-out-query-navigation="withOutQueryNavigation"
              :query-params="queryParams"
              :category-id-parent="props.categories[index]?.id"
              :categories="childrenCategory[index]"
              @go-catgory-id="emit('go-catgory-id', $event)"
            />
          </div>
        </template>
      </RikazCollection>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'
import { useCategoriesCrumbsStore } from '~/store/categories_crumbs'
const { t } = useI18n()
const { addCrumb, path: pathCrumps } = useCategoriesCrumbsStore()
const route = useRoute()
const collectionRef1 = ref<HTMLElement | null>(null)
const fetched = ref<Record<string, boolean>>({})
const props = withDefaults(
  defineProps<{
    categories: Category[]
    categoryIdParent: number
    queryParams?: object
    enableNavigate?: boolean
    firstChild?: boolean
    withOutQueryNavigation?: boolean
  }>(),
  {
    firstChild: false,
    enableNavigate: false,
    withOutQueryNavigation: false,
    queryParams: () => ({})
  }
)
/* في ديالوغ الفلاتر لا نريد مباشرة تغيير الكويري وإنما تخزين التغييرات ومن ثم الضغط على زر تطبيق لذلك تم استخدام هذا الفانكشن */
function QueryNavigation(categoryId?: number) {
  if (props.withOutQueryNavigation) {
    emit('go-catgory-id', categoryId)
  } else {
    useQueryNavigation({ category_id: categoryId })
  }
}
const selectedIndex = ref(-1)
const childrenCategory = ref<Record<string, Category[]>>({})

const emit = defineEmits(['go-route', 'go-catgory-id'])

function goToCategory(index?: number) {
  if (index !== undefined) {
    const categoryId = props.categories[index]?.id
    if (props.enableNavigate && props.firstChild) {
      /*    للذهاب إلى صفحة معينة عند اختيار الولد الاول  */
      emit('go-route', categoryId)
    } else {
      if (selectedIndex.value === index) {
        /* في حال تم اختيار هذا التصنيف سابقا إلفاء تحديده والذهاب لتحديد التصنيف الاب */
        selectedIndex.value = -1
        QueryNavigation(props.categoryIdParent)
      } else {
        /* اختيار هذا التصنيف */
        selectedIndex.value = index
        QueryNavigation(categoryId)
      }
      openChildren(index, categoryId)
    }
  } else if (route.query.category_id !== props.categoryIdParent.toString()) {
    /*    لاختيار التصنيف الاب */
    QueryNavigation(props.categoryIdParent)
    selectedIndex.value = -1
  }
}
async function openChildren(index: number, categoryId: number | string) {
  if (!fetched.value[index]) {
    fetched.value[index] = true
    try {
      const { data: categoryNewFetch, error } = await useApiFetch(
        '/category/' + categoryId,
        {
          query: {
            with_children: true,
            ...props.queryParams
          },

          transform: (data: SuccessResponse<Category>) => {
            const { categoryModel } = useMenuModel()

            return categoryModel(data.data)
          }
        }
      )

      if (error?.value) {
        throw error.value
      }
      if (categoryNewFetch.value) {
        addCrumb(categoryNewFetch.value)
      }

      childrenCategory.value[index] = categoryNewFetch.value?.children ?? []
    } catch (error) {
      childrenCategory.value[index] = []
      fetched.value[index] = true
    }
  }
}
const isDomain = useIsDomain()
onMounted(() => {
  toggleQueryCategoryID()
})
function toggleQueryCategoryID() {
  const categoryIdTemp = route.query.category_id
  if (categoryIdTemp) {
    const categoriesCrumbs = pathCrumps
    props.categories?.forEach((el, index) => {
      const tempFilterArray = categoriesCrumbs.filter((cat) => cat.id === el.id)
      if (tempFilterArray.length) {
        if (tempFilterArray[0].children === undefined) {
          openChildren(index, tempFilterArray[0].id)
        } else {
          childrenCategory.value[index] = tempFilterArray[0].children
        }
        selectedIndex.value = index
        collectionRef1.value.setIndex(index)
        collectionRef1.value.open(true)
      }
    })
  }
}
</script>

<style scoped>
.v-icon--size-default.custom-font-size {
  @apply text-[20px] xs:text-[25px] sm:text-[28px] md:text-[34px]  lg:text-[50px] !important;
}
</style>
