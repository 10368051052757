import { defineStore } from 'pinia'
import type { Category } from '~/composables/useMenuModel'

export const useCategoriesCrumbsStore = defineStore('categories_crumbs', () => {
  const crumbs = ref<Record<number, Category>>({})
  const path = computed(() => {
    const categoryId = useRoute().query.category_id
    if (categoryId) {
      return appendPath(+categoryId, [])
    } else {
      return []
    }
  })
  const selectedCategory = computed(() => {
    if (path.value.length) {
      return path.value[path.value.length - 1]
    } else {
      return undefined
    }
  })
  const categoryId = computed(() => {
    return useRoute().query.category_id as string | undefined
  })
  async function fetchCategoryQueryWithAncestors(storeId:string | string[]) {
    const store = useCategoriesCrumbsStore()
    if (categoryId.value) {
      const { data } = await useApiFetch<ApiResponse<Category>>(
        '/category/' + categoryId.value,
        {
          query: { with_ancestors: true, store_id: storeId }
        }
      )

      const category = data.value?.data

      if (category) {
        store.addCrumb(category)
        category.ancestors?.forEach((el) => {
          store.addCrumb(el)
        })
      }
    }
  }
  function appendPath(id: number, path: Category[]) {
    const crumb = crumbs.value[id]

    if (crumb?.parent_id && crumbs.value[crumb.parent_id]) {
      return appendPath(crumb.parent_id, [crumb, ...path])
    }

    if (crumb) {
      return [crumb, ...path]
    }

    return []
  }

  function addCrumb(crumb: Category) {
    crumbs.value[crumb.id] = crumb
  }

  function clear() {
    crumbs.value = {}
  }

  return {
    path,
    selectedCategory,
    fetchCategoryQueryWithAncestors,
    addCrumb,
    clear
  }
})
